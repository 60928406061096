<template>
  <div>
    <label v-if="label">{{ label }}</label>
    <vue-multiselect
        :class="internalValue ? 'input-selected' : ''"
        v-model="internalValue"
        @select="selectedValue => $emit('select', selectedValue)"
        @remove="$emit('remove')"
        :options="options"
        :custom-label="customLabel"
        :placeholder="placeholder"
        :show-labels="false">
      <template slot="singleLabel" slot-scope="{ option }">
        <span @mousedown.prevent.stop="reset" @focus.prevent.stop="reset" class="clickable-element text-danger font-size-14">
          <i class="fa fa-times" />
        </span>

        {{ customLabel(option) }}
      </template>

    </vue-multiselect>
  </div>
</template>
<script>

export default {
  name: "multiselect",

  props: {
    options: {
      type: Array,
      default: () => []
    },

    value: {
      type: String
    },

    label: {
      type: String
    },

    placeholder: {
      type: String
    },

    customLabel: {
      type: Function,
      default: (option) => option
    }
  },

  watch: {
    internalValue: {
      deep: true,
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$emit("input", newValue)
        }
      }
    },

    value: {
      deep: true,
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.internalValue = newValue
        }
      }
    },
  },

  data() {
    return {
      internalValue: this.value
    }
  },

  methods: {
    reset() {
      this.$emit('select')
      this.$emit('input')
    }
  }

}
</script>

<style scoped>
.icon {
  max-width: 24px;
  max-height: 24px;
}
</style>