<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Switches from "vue-switches";
import DatePicker from "vue2-datepicker";
import {paginationHelper} from "@/helpers/pagination-helper";
import axios from "axios";
import {dateUtil} from "@/helpers/date-util";
import {errorCatcher} from "@/helpers/error-catcher";
import {required} from "vuelidate/lib/validators";
import {swalHelper} from "@/helpers/swal-helper";
import allCountries from "@/helpers/all-countries";
import {routerHelper} from "@/helpers/router-helper";
import Swal from "sweetalert2";
import {orderHelper} from "@/helpers/order-helper";
import OrderAddressButton from "../../../components/core/order-address-button.vue";
import Multiselect from "@/components/multiselect.vue";
import EcatInput from "@/components/ecat-input.vue";
import {queryHelper} from "@/helpers/query-helper";

/**
 * Core Orders Component
 */
export default {

  computed: {
    orderHelper() {
      return orderHelper
    },

    dateUtil() {
      return dateUtil
    },

    paginationHelper() {
      return paginationHelper
    },

    optionsWithDeselect() {
      return (options) => {
        return [...['reset-option'], ...options];
      };
    }
  },

  components: {
    EcatInput,
    Multiselect,
    OrderAddressButton,
    Layout,
    PageHeader,
    Switches,
    DatePicker
  },

  validations: {
    modals: {
      changeProductStatus: {
        status: {required}
      },

      createOrder: {
        order: {
          shopId: {required},

          firstName: {required},
          lastName: {required},
          email: {required},

          carrierId: {required},

          street: {required},
          city: {required},
          postCode: {required},
          country: {required},
        }
      }
    }
  },

  data() {
    return {
      isDropdownVisible: false,

      loadedShopOrderIdFromQuery: "",
      // loadedCurrentPageFromQuery: 1,

      loadedQuery: false,

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: "",
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        isBusy: false
      },

      carriersIds: null,
      carriers: null,
      deliveryAddresses: new Map(),

      orderId: null,

      shopOrders: null,
      shopOrderProducts: null,
      shopOrderProductStatuses: null,
      shopOrderProductStatusTimes: null,

      modals: {

        changeProductStatus: {

          visibleModal: false,
          status: "",
          productIds: []

        },

        createOrder: {

          visibleModal: false,

          order: {
            shopId: "",

            firstName: "",
            lastName: "",
            email: "",

            carrierId: "",
            parcelLockerCode: "",

            street: "",
            city: "",
            postCode: "",
            country: ""

          }

        }

      },

      options: {
        shopOrderStatus: [
          "ORDER_PLACED",
          "ORDER_IN_PROGRESS",
          "ORDER_COMPLETED",
          "ORDER_CANCELED"
        ],

        paymentStatus: [
          "WAITING",
          "SUCCESS",
          "FAILED"
        ],

        carrier: [],

        orderRealizer: [
          "LAST_MODIFIED",
          "ANY_MODIFIED"
        ]
      },

      filtering: {
        showArchived: false,
        showDeleted: false,
        allegroAccountName: ""
      },

      selectors: {
        shopOrderStatus: {
          selected: ''
        },

        shopOrderProductStatus: {
          selected: ''
        },

        paymentStatus: {
          selected: ''
        },

        warehouse: {
          selected: "",

          orderNumber: "",
          price: 0,
          fvNumber: ""
        },

        fvNumber: "",

        carrier: {
          selected: null
        },

        phoneNumber: '',
        orderRealizer: {
          selectedRealizerId: '',

          selected: ''
        },

        withoutWaybills: false,
        manuallyAddOrder: false,

        startDate: '',
        endDate: '',

        trackingNumber: ""
      },

      inputPage: ""
    }
  },

  watch: {
    "table.currentPage": {
      deep: true,
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue && oldValue !== undefined) {
          this.fillQuery()
        }
      }
    },

    "table.perPage": {
      deep: true,
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue && oldValue !== undefined) {
          this.fillQuery()
        }
      }
    }
  },

  methods: {

    selectWarehouse(value) {
      this.selectors.warehouse.selected = value
      this.fillQuery()
    },

    fillQuery() {
      const query = {}
      if (this.table.currentPage) {
        query["currentPage"] = this.table.currentPage
      }

      if (this.table.perPage) {
        query["perPage"] = this.table.perPage
      }

      if (this.selectors.warehouse.selected) {
        query["warehouseId"] = this.selectors.warehouse.selected
      }

      if (this.selectors.warehouse.orderNumber) {
        query["orderNumber"] = this.selectors.warehouse.orderNumber
      }

      if (this.selectors.warehouse.price) {
        query["price"] = this.selectors.warehouse.price
      }

      if (this.selectors.trackingNumber) {
        query["trackingNumber"] = this.selectors.trackingNumber
      }

      if (this.selectors.fvNumber) {
        query["fvNumber"] = this.selectors.fvNumber
      }

      if (this.selectors.warehouse.fvNumber) {
        query["warehouseFvNumber"] = this.selectors.warehouse.fvNumber
      }

      if (this.selectors.shopOrderStatus.selected) {
        query["shopOrderStatus"] = this.selectors.shopOrderStatus.selected
      }

      if (this.selectors.paymentStatus.selected) {
        query["paymentStatus"] = this.selectors.paymentStatus.selected
      }

      if (this.selectors.shopOrderProductStatus.selected) {
        query["shopOrderProductStatus"] = this.selectors.shopOrderProductStatus.selected
      }

      if (this.selectors.phoneNumber) {
        query["phoneNumber"] = this.selectors.phoneNumber
      }

      if (this.selectors.carrier.selected) {
        query["carrierId"] = this.selectors.carrier.selected.id
      }

      if (this.selectors.withoutWaybills) {
        query["withoutWaybills"] = this.selectors.withoutWaybills
      }

      if (this.selectors.startDate) {
        query["startDate"] = this.selectors.startDate
      }

      if (this.selectors.endDate) {
        query["endDate"] = this.selectors.endDate
      }

      // if (shopOrderId) {
      //   query["shopOrderId"] = shopOrderId
      // }

      queryHelper.set(query)
    },

    loadQuery() {
      if (this.$route.query.shopOrderId) {
        this.loadedShopOrderIdFromQuery = this.$route.query.shopOrderId
      }

      // if (this.$route.query.currentPage) {
      //   this.loadedCurrentPageFromQuery = Number(this.$route.query.currentPage)
      // }

      if (this.$route.query.perPage) {
        this.table.perPage = this.$route.query.perPage
      }

      if (this.$route.query.warehouseId) {
        this.selectors.warehouse.selected = this.$route.query.warehouseId
      }

      if (this.$route.query.orderNumber) {
        this.selectors.warehouse.orderNumber = this.$route.query.orderNumber
      }

      if (this.$route.query.price) {
        this.selectors.warehouse.price = this.$route.query.price
      }

      if (this.$route.query.trackingNumber) {
        this.selectors.trackingNumber = this.$route.query.trackingNumber
      }

      if (this.$route.query.fvNumber) {
        this.selectors.fvNumber = this.$route.query.fvNumber
      }

      if (this.$route.query.warehouseFvNumber) {
        this.selectors.warehouse.fvNumber = this.$route.query.warehouseFvNumber
      }

      if (this.$route.query.shopOrderStatus) {
        this.selectors.shopOrderStatus.selected = this.$route.query.shopOrderStatus
      }

      if (this.$route.query.paymentStatus) {
        this.selectors.paymentStatus.selected = this.$route.query.paymentStatus
      }

      if (this.$route.query.shopOrderProductStatus) {
        this.selectors.shopOrderProductStatus.selected = this.$route.query.shopOrderProductStatus
      }

      if (this.$route.query.phoneNumber) {
        this.selectors.phoneNumber = this.$route.query.phoneNumber
      }

      if (this.$route.query.carrierId) {
        this.selectors.carrier.selected = this.getCarrier(this.$route.query.carrierId)
      }

      if (this.$route.query.withoutWaybills) {
        this.selectors.withoutWaybills = this.$route.query.withoutWaybills
      }

      if (this.$route.query.startDate) {
        this.selectors.startDate = this.$route.query.startDate
      }

      if (this.$route.query.endDate) {
        this.selectors.endDate = this.$route.query.endDate
      }

      this.loadedQuery = true
    },

    getTextOr(translation, value) {
      if (value === "reset-option") {
        return "Wszystkie statusy"
      }

      return this.$t(translation + value)
    },

    async loadMultiSelect(id, field) {
      const multiSelect = await this.$store.getters['multiselect/getMultiselect'](id)
      if (!multiSelect) {
        return
      }

      field.selected = multiSelect.value
    },

    selectMultiSelect(id, value) {
      if (value === "reset-option") {
        value = ""
      }

      this.$store.dispatch("multiselect/setMultiselect", {id: id, value: value})
      this.setToFirstPageAndRefresh()
    },

    // async setSelectedShopId(shopOrder, middle = false) {
    //   // console.log(shopOrder.id, middle)
    //   this.fillQuery(shopOrder.id)
    //
    //   console.log("query:", this.$route.query)
    //   // await this.$store.dispatch("shoporder/setSelectedShopOrderQuery", this.$route.fullPath)
    //
    //   console.log(middle)
    //   // const url = this.getOrderDetailsUrl(shopOrder)
    //   // if (middle) {
    //   //   window.open(url, '_blank').focus();
    //   // } else {
    //   //   window.location = url
    //   // }
    // },

    getOrderDetailsUrl(shopOrder) {
      const currentQueryParams = this.$route.query;
      const newQueryParams = {
        ...currentQueryParams,
        shopOrderId: shopOrder.id
      };

      return `/dashboard/core/order/details?${Object.entries(newQueryParams).map(([key, value]) => `${key}=${value}`).join('&')}`;
    },

    setToFirstPageAndRefresh() {
      this.fillQuery()
      this.paginationHelper.setToFirstPageAndRefresh(this, this.table, 'table')
    },

    getIdText(item) {
      if (!item) {
        return ''
      }

      let id = item.idAsNumber + " "
      if (item.allegroAccountName) {
        id += " / " + '(' + item.allegroAccountName + ')'
      }

      id += " / " + dateUtil.asDateTimeNormal(item.createdAt)

      if (item.shopDomain) {
        id += " / " + item.shopDomain
      } else if (item.shopName) {
        id += " / " + item.shopName
      }

      return id
    },

    highlightRow(item) {
      if (!item) {
        return ''
      }

      return this.modals.changeProductStatus.productIds.includes(item.id) ? 'highlighted-order' : 'default-order';
    },

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('core-orders.title'),
          active: true
        }
      ]
    },

    getFields() {
      return [
        {key: "id", label: 'ID'},
        {key: "pricesSum", label: 'Wartość zam. brutto', formatter: value => value.toFixed(2) + " PLN"},
        {key: "totalProductsCostTaxExcluded", label: 'Przychód (netto)', formatter: value => value.toFixed(2) + " PLN"},
        {key: 'lastModification', label: 'Ostatnia modyfikacja'},
        {key: "paymentStatus", label: 'Płatność'},
        {key: "action", label: this.$t('table.actions')}
      ]
    },

    getProductsFields() {
      return [
        {key: "name", label: 'Produkt'},
        {key: "quantity", label: 'Ilość'},
        {
          key: "wholesalePrice", label: 'Cena jedn. hurtowa netto', formatter: value => {
            if (!value) {
              return "-"
            }

            return value.toFixed(2) + " PLN"
          }
        },
        {key: "totalValueTaxExcluded", label: 'Cena jedn. netto', formatter: value => value.toFixed(2) + " PLN"},
        {key: "totalValueTaxIncluded", label: 'Cena jedn. brutto', formatter: value => value.toFixed(2) + " PLN"},
        {key: "warehouse", label: 'Hurtownia'},
        {key: "status", label: 'Status'}
      ]
    },

    getOrderProducts(shopOrder) {
      const products = this.shopOrderProducts[shopOrder.id]
      return products ? products : []
    },

    getProductLeftLabelColor(shopOrder) {
      const products = this.getOrderProducts(shopOrder);
      if (!products || products.length === 0) {
        return ''
      }

      const product = products[0];
      if (!product) {
        return ''
      }

      const currentTime = new Date().getTime();
      const status = this.getShopOrderProductTime(product.shopOrderProductStatus)
      const diff = currentTime - new Date(shopOrder.createdAt).getTime();

      if (status && status.time > 0 && diff > status.time) {
        return 'status-background-11'
      }

      return orderHelper.getProductTextStatusBackgroundColor(product.shopOrderProductStatus)
    },

    getProductRightLabelColor(shopOrder) {
      const products = this.getOrderProducts(shopOrder);
      if (!products || products.length === 0) {
        return ''
      }

      const product = products[0];
      if (!product) {
        return ''
      }

      return orderHelper.getProductTextStatusBackgroundColor(product.shopOrderProductStatus)
    },

    paymentStatusText(paymentStatus) {
      if (paymentStatus === "reset-option") {
        return "Wszystkie statusy"
      }

      switch (paymentStatus) {
        case 'WAITING':
          return 'Do weryfikacji'
        case 'SUCCESS':
          return 'Opłacona'
        case 'FAILED':
          return 'Nieopłacona'
      }
    },

    paymentStatusColor(paymentStatus) {
      switch (paymentStatus) {
        case 'WAITING':
          return 'badge-soft-warning'
        case 'SUCCESS':
          return 'badge-soft-success'
        case 'FAILED':
          return 'badge-soft-danger'
      }
    },

    hideCreateOrderModal() {
      this.modals.createOrder.visibleModal = false
      this.modals.createOrder.order = {
        shopId: "",

        firstName: "",
        lastName: "",
        email: "",

        carrierId: "",

        street: "",
        city: "",
        postCode: "",
        country: ""
      }
    },

    createOrder() {
      if (!this.modals.createOrder.order.carrierId) {
        Swal.fire("Błąd", "Wybierz sposób wysyłki!", "error");
        return
      }

      const carrier = this.getCarrier(this.modals.createOrder.order.carrierId)
      if (!carrier) {
        Swal.fire("Błąd", "Wybierz sposób wysyłki!", "error");
        return
      }

      if (carrier.name.toLowerCase().includes('paczkomat') && !this.modals.createOrder.order.parcelLockerCode) {
        Swal.fire("Błąd", "Wprowadź kod paczkomatu", "error");
        return
      }

      const json = JSON.stringify({
        "customerEmail": this.modals.createOrder.order.email,
        "manuallyOrder": this.selectors.manuallyAddOrder,
        "carrierId": this.modals.createOrder.order.carrierId,
        "parcelLockerCode": this.modals.createOrder.order.parcelLockerCode,
        "shopId": this.modals.createOrder.order.shopId,
        "address": {
          "city": this.modals.createOrder.order.city,
          "street": this.modals.createOrder.order.street,
          "postCode": this.modals.createOrder.order.postCode,
          "country": this.modals.createOrder.order.country,
          "firstName": this.modals.createOrder.order.firstName,
          "lastName": this.modals.createOrder.order.lastName
        },

        "customerDetails": {
          "firstName": this.modals.createOrder.order.firstName,
          "lastName": this.modals.createOrder.order.lastName,
          "phoneNumber": ""
        }
      });

      axios.post(`/shop-order/create`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then((result) => {
        this.$bvToast.toast(this.selectors.manuallyAddOrder ? 'Pomyślnie utworzono zamówienie stacjonarne!' : 'Pomyślnie utworzono zamówienie', {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        routerHelper.push(`/dashboard/core/order/details?shopOrderId=${result.data.id}`)
        this.submitted = false;
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    },

    getAllCountries() {
      return allCountries;
    },

    resetFilters() {
      this.selectors = {
        shopOrderStatus: {
          selected: ''
        },

        shopOrderProductStatus: {
          selected: ''
        },

        paymentStatus: {
          selected: ''
        },

        warehouse: {
          selected: "",

          orderNumber: "",
          price: 0,
          fvNumber: ""
        },

        fvNumber: "",

        carrier: {
          selected: null
        },

        phoneNumber: '',
        orderRealizer: {
          selectedRealizerId: '',

          selected: ''
        },

        withoutWaybills: false,
        manuallyAddOrder: false,

        startDate: '',
        endDate: '',
        trackingNumber: ""
      };

      this.selectMultiSelect("18", "")
      this.selectMultiSelect("19", "")
      this.selectMultiSelect("20", "")
      this.setToFirstPageAndRefresh()

      this.$bvToast.toast(this.$t('core-orders.filters.success'), {
        title: this.$t('message.success'),
        variant: 'success',
        solid: true
      });
    },

    disableModalChangeProductStatus() {
      this.modals.changeProductStatus.visibleModal = false
      this.modals.changeProductStatus.status = ''
      this.modals.changeProductStatus.productIds = []
    },

    async loadShopOrders() {
      try {
        await this.loadMultiSelect("18", this.selectors.shopOrderStatus)
        await this.loadMultiSelect("19", this.selectors.paymentStatus)
        await this.loadMultiSelect("20", this.selectors.shopOrderProductStatus)

        // if (this.loadedCurrentPageFromQuery) {
        //   this.table.currentPage = this.loadedCurrentPageFromQuery
        //   this.loadedCurrentPageFromQuery = 0
        // }

        if (!this.orderId) {
          let page = this.table.currentPage - 1;
          if (page > 0) {
            page = this.table.currentPage * this.table.perPage - this.table.perPage;
          }

          const {data} = await axios.get(`/shop-order/pagination`, {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json'
            },
            data: {},
            params: {
              "page": page,
              "size": this.table.perPage,
              "filter": this.table.filter,
              "warehouseOrderId": this.selectors.warehouse.orderNumber,
              "wholesalePrice": this.selectors.warehouse.price ? this.selectors.warehouse.price : 0,
              "shopOrderProductStatus": this.selectors.shopOrderProductStatus.selected ? this.selectors.shopOrderProductStatus.selected : "",
              "phoneNumber": this.selectors.phoneNumber,
              "carrierId": this.selectors.carrier.selected ? this.selectors.carrier.selected.id : "",
              "shopOrderStatus": this.selectors.shopOrderStatus.selected ? this.selectors.shopOrderStatus.selected : "",
              "warehouseInvoiceId": this.selectors.warehouse.fvNumber,
              "warehouseId": this.selectors.warehouse.selected ? this.selectors.warehouse.selected : "",
              "paymentStatus": this.selectors.paymentStatus.selected ? this.selectors.paymentStatus.selected : "",
              "withoutWaybills": this.selectors.withoutWaybills,
              "trackingNumber": this.selectors.trackingNumber ? this.selectors.trackingNumber : "",
              ...this.filtering
            }
          });

          // TODO filtrowanie po: bez listow przewozowych, realizator, typ realizatora
          // data poczatkowa, data koncowa, status platnosci

          this.shopOrders = data.shopOrders
          this.table.totalRows = data.count;
          this.table.rows = data.count;
        } else {
          const {data} = await axios.get(`/shop-order/fetch`, {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json'
            },
            data: {},
            params: {
              "shopOrderId": this.orderId,
            }
          });

          this.shopOrders = [data]
          this.table.totalRows = this.shopOrders.length;
          this.table.rows = this.shopOrders.length;
        }

        await this.loadShopOrderProducts()
        await this.loadDeliveryAddresses()

        try {
          paginationHelper.expandAll(this, this.shopOrders);
        } catch (error) {
          console.log(error)
        }
      } catch (error) {
        this.shopOrders = []
        this.table.totalRows = 0;
        this.table.rows = 0;
      }

      setTimeout(() => {
        if (this.loadedShopOrderIdFromQuery) {
          const element = document.getElementById(this.loadedShopOrderIdFromQuery);
          if (element) {
            const offset = element.getBoundingClientRect().top + window.scrollY;
            const adjustedOffset = offset - 100;

            window.scrollTo({
              top: adjustedOffset,
              behavior: "auto"
            });
          }

          this.loadedShopOrderIdFromQuery = ""
          // if (this.loadedCurrentPageFromQuery) {
          //   this.loadedCurrentPageFromQuery = 0
          // }
        }
      }, 850)

      return this.shopOrders
    },

    async loadShopOrderProducts() {
      if (this.shopOrders.length === 0) {
        return;
      }

      const ids = [];
      for (let shopOrder of this.shopOrders) {
        ids.push(shopOrder.id);
      }

      const json = JSON.stringify({
        "ids": ids
      });

      const result = await axios.post(`/shop-order/product/fetch/by/shop-order`, json, {
        data: {},
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      });

      this.shopOrderProducts = {};

      for (const id of ids) {
        const orderProducts = []

        for (const orderProduct of result.data) {
          if (orderProduct.shopOrderId === id) {
            orderProducts.push(orderProduct)
          }
        }

        this.shopOrderProducts[id] = orderProducts
      }
    },

    async loadDeliveryAddresses() {
      try {
        if (this.shopOrders.length === 0) {
          return;
        }

        const ids = this.shopOrders.map(shopOrder => shopOrder.id);

        const {data} = await axios.get(`/shop-order/delivery-addresses`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          params: {
            shopOrderIds: ids.join(",")
          }
        });

        this.deliveryAddresses = new Map(data.map((obj) => [obj.shopOrderId, obj]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadShopOrderProductStatus() {
      try {
        const result = await axios.get(`/shop-order/product/status`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.shopOrderProductStatuses = result.data
        await this.loadShopOrderProductStatusTimes()
      } catch (error) {
        console.log(error)
      }
    },

    async loadShopOrderProductStatusTimes() {
      try {
        const result = await axios.get(`/shop-order/product/status/times`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.shopOrderProductStatusTimes = new Map(result.data.map((obj) => [obj.status, obj]));
      } catch (error) {
        console.log(error)
      }
    },

    getShopOrderProductTime(statusType) {
      if (!this.shopOrderProductStatusTimes) {
        return null
      }

      return this.shopOrderProductStatusTimes.get(statusType) || null
    },

    async loadCarriers() {
      try {
        const result = await axios.get(`/carrier`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        this.options.carrier = result.data

        this.carriersIds = []
        for (let carrier of result.data) {
          this.carriersIds.push(carrier.id);
        }

        this.carriers = new Map(result.data.map((obj) => [obj.id, obj]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    getCarrier(id) {
      if (!this.carriers) {
        return {}
      }

      const carrier = this.carriers.get(id)
      if (!carrier) {
        return {}
      }

      return carrier
    },

    deliveryAddress(shopOrderId) {
      if (!this.deliveryAddresses) {
        return null
      }

      return this.deliveryAddresses.get(shopOrderId) || null
    },

    archiveOrUnArchiveOrder(order, archived) {
      const archivedOrder = Object.assign({}, order)
      archivedOrder.archived = archived
      delete archivedOrder._showDetails

      const json = JSON.stringify(archivedOrder);

      axios.put(`/shop-order`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(() => {
        this.$bvToast.toast(archived ? 'Zamówienie zarchiwizowane' : "Zamówienie przywrócone", {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        this.$refs.table.refresh()
      }).catch((error) => {
        errorCatcher.catchErrors(error)
      })
    },

    deleteOrder(order, deleted) {
      const deletedOrder = Object.assign({}, order)
      deletedOrder.deleted = deleted
      delete deletedOrder._showDetails

      const json = JSON.stringify(deletedOrder);

      axios.put(`/shop-order`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(() => {
        this.$bvToast.toast(deleted ? "Zamówienie usunięte" : "Zamówienie przywrócone", {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        this.$refs.table.refresh()
      }).catch((error) => {
        errorCatcher.catchErrors(error)
      })
    },

    changeProductStatus() {
      if (this.modals.changeProductStatus.productIds.length === 0) {
        this.$bvToast.toast('Wybierz przynajmniej jeden produkt!', {
          title: this.$t('message.error'),
          variant: 'danger',
          solid: true
        });

        return;
      }

      swalHelper.yesOrNoWithSubTitle(() => {
        this.$v.modals.changeProductStatus.$touch();

        if (this.$v.modals.changeProductStatus.$invalid) {
          return;
        }

        const json = JSON.stringify({
          "ids": this.modals.changeProductStatus.productIds,
          "status": this.modals.changeProductStatus.status
        })

        axios.post(`/shop-order/product/update-status`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        }).then(() => {
          this.$bvToast.toast('Pomyślnie zmieniono statusy produktów', {
            title: this.$t('message.success'),
            variant: 'success',
            solid: true
          });

          setTimeout(() => this.$router.go(this.$router.currentRoute), 350)
        }).catch((error) => {
          errorCatcher.catchErrors(error)
        })
      }, false)
    }

  },

  async beforeMount() {
    try {
      await this.loadShopOrderProductStatus()
      await this.loadCarriers()
    } catch (error) {
      console.log(error)
    }

    this.loadQuery()
  },

  async created() {
    try {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const urlParams = Object.fromEntries(urlSearchParams.entries());
      if (urlParams.orderId) {
        this.orderId = urlParams.orderId
      }
    } catch (error) {
      console.log(error)
    }
  }

};
</script>

<template>
  <Layout>
    <PageHeader :items="getItems()" :title="$t('core-orders.title')"/>
    <div class="card">
      <div class="card-body" v-if="loadedQuery">
        <div class="row">
          <div class="col-lg-12 mb-4">
            <h5 class="text-start p-2 card-subtitle">Filtrowanie</h5>
          </div>

          <div class="col-12 col-xl-2">
            <h5>Hurtownie</h5>
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-xl-12">
                <div>
                  <ecat-multiselect
                      :set-value="selectors.warehouse.selected"
                      multi-select-id="5"
                      @change="value => selectWarehouse(value)"
                      @select="setToFirstPageAndRefresh"
                      save-id="id"
                      view-id="name"
                      label="Hurtownia"
                      placeholder="Wszystkie hurtownie"
                      fetch-one-url="/warehouse"
                      load-url="/warehouse/list/pagination"
                      query-url="/warehouse/by-name"
                      param="name"
                      :can-unselect="true"
                  />
                </div>
                <div>
                  <ecat-input
                      label="Numer zamówienia w hurtowni"
                      id="fv-number"
                      v-model="selectors.warehouse.orderNumber"
                      type="text"
                      @should-reload="setToFirstPageAndRefresh"
                  />
                </div>
                <div>
                  <ecat-input
                      label="Kwota zamówienia w hurtowni"
                      id="warehouse-price"
                      v-model="selectors.warehouse.price"
                      type="text"
                      @should-reload="setToFirstPageAndRefresh"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-xl-2">
            <h5>Pracownicy ec-at</h5>
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-xl-12">
                <label for="order-realizer">Realizator zamówienia:</label> <!-- TODO -->
                <multiselect
                    class="my-2"
                    v-model="selectors.orderRealizer.selectedRealizerId"
                    :options="options.orderRealizer"
                    :custom-label="value => $t('core-orders.order.realizer.status.' + value)"
                    placeholder="Wybierz realizatora"
                    @select="setToFirstPageAndRefresh"
                />

                <multiselect
                    v-model="selectors.orderRealizer.selected"
                    :options="options.orderRealizer"
                    :custom-label="value => $t('core-orders.order.realizer.status.' + value)"
                    placeholder="Wybierz typ realizatora"
                    @select="setToFirstPageAndRefresh"
                />
              </div>
            </div>
          </div>

          <div class="col-12 col-xl-2">
            <h5>Faktury</h5>
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-xl-12">
                  <ecat-input
                      label="Numer faktury"
                      id="warehouse-price"
                      v-model="selectors.fvNumber"
                      type="text"
                      @should-reload="setToFirstPageAndRefresh"
                  />

                <ecat-input
                    label="Numer faktury hurtowni"
                    id="warehouse-price"
                    v-model="selectors.warehouse.fvNumber"
                    type="text"
                    @should-reload="setToFirstPageAndRefresh"
                />
              </div>
            </div>
          </div>

          <div class="col-12 col-xl-2">
            <h5>Statusy</h5>
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-xl-12">
                <label>Status zamówienia</label>
                <multiselect
                    v-model="selectors.shopOrderStatus.selected"
                    :options="optionsWithDeselect(options.shopOrderStatus)"
                    :custom-label="value => getTextOr('core-orders.order.status.', value)"
                    placeholder="Wybierz status zamówienia"
                    @select="value => selectMultiSelect('18', value)"
                    @remove="selectMultiSelect('18', '')"
                />

                <label>Status platności</label>
                <multiselect
                    v-model="selectors.paymentStatus.selected"
                    :options="optionsWithDeselect(options.paymentStatus)"
                    :custom-label="value => paymentStatusText(value)"
                    placeholder="Wybierz status płatności"
                    @select="value => selectMultiSelect('19', value)"
                    @remove="selectMultiSelect('19', '')"
                />

                <label>Status produktu w zamówieniu</label>
                <multiselect
                    v-model="selectors.shopOrderProductStatus.selected"
                    :options="shopOrderProductStatuses ? optionsWithDeselect(shopOrderProductStatuses) : []"
                    :custom-label="value => getTextOr('core-orders.product.status.', value)"
                    placeholder="Wybierz status produktu"
                    @select="value => selectMultiSelect('20', value)"
                    @remove="selectMultiSelect('20', '')"
                />
              </div>
            </div>
          </div>

          <div class="col-12 col-xl-2">
            <h5>Inne</h5>
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-xl-12">
                <ecat-input
                    label="Numer telefonu"
                    id="phone-number"
                    v-model="selectors.phoneNumber"
                    type="text"
                    @should-reload="setToFirstPageAndRefresh"
                />
                <ecat-input
                    label="Nazwa konta Allegro"
                    v-model="filtering.allegroAccountName"
                    type="text"
                    @should-reload="setToFirstPageAndRefresh"
                />
              </div>
            </div>
          </div>

          <div class="col-12 col-xl-2">
            <h5>Data wpłynięcia zamówienia</h5>
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-xl-12">
                <label>Data początkowa</label>
                <date-picker @input="setToFirstPageAndRefresh" v-model="selectors.startDate" lang="pl-PL"></date-picker>

                <label>Data końcowa</label>
                <date-picker @input="setToFirstPageAndRefresh" v-model="selectors.endDate" lang="pl-PL"></date-picker>
              </div>
            </div>
          </div>

          <div class="col-12 col-xl-2">
            <h5>Śledzenie</h5>
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-xl-12">
                <ecat-input
                    label="Numer śledzenia"
                    v-model="selectors.trackingNumber"
                    type="text"
                    @should-reload="setToFirstPageAndRefresh"
                />
              </div>
            </div>
          </div>

          <div class="col-12 col-xl-2">
            <h5>Przewoźnicy</h5>
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-xl-12">
                <label>Przewoźnicy</label>
                <vue-multiselect
                    v-model="selectors.carrier.selected"
                    @select="setToFirstPageAndRefresh"
                    :options="options.carrier ? options.carrier : []"
                    :custom-label="value => value.name"
                    placeholder="Wybierz przewoźnika"
                    :show-labels="false"
                />

                <p><b>{{
                    $t('core-orders.without-waybills.button', {'status': $t(selectors.withoutWaybills ? 'message.yes' : 'message.no')})
                  }}</b></p>
                <switches @input="setToFirstPageAndRefresh" v-model="selectors.withoutWaybills" :emit-on-mount="false"
                          class="ml-2" color="primary"></switches>
              </div>
            </div>
          </div>

          <div class="col-md-12 py-4">
            <b-button variant="primary" @click="setToFirstPageAndRefresh" class="w-xxl mr-2">Szukaj</b-button>
            <b-button variant="outline-info" @click="resetFilters" class="w-xxl">Wyczyść Filtry</b-button>

            <div class="d-inline-flex">
              <div class="d-flex flex-sm-column mx-2">
                <switches :emit-on-mount="false" @input="setToFirstPageAndRefresh" v-model="filtering.showArchived"
                          class="my-0" color="warning"/>
                <label class="font-size-12 py-1">
                  Pokazuj zamówienia zarchiwizowane
                </label>
              </div>
            </div>

            <div class="d-inline-flex" v-if="filtering.showArchived">
              <div class="d-flex flex-sm-column mx-2">
                <switches :emit-on-mount="false" @input="setToFirstPageAndRefresh" v-model="filtering.showDeleted"
                          class="my-0" color="danger"/>
                <label class="font-size-12 py-1">
                  Pokazuj zamówienia usunięte
                </label>
              </div>
            </div>
          </div>

          <div class="col-lg-12 mb-4">
            <h5 class="text-start p-2 card-subtitle">Akcje</h5>
          </div>

          <div class="col-md-12 pb-4">
            <div class="d-inline-flex">
              <b-button @click="modals.changeProductStatus.visibleModal = true" class="w-xl mx-2 px-4"
                        variant="outline-info">
                {{ $t('core-orders.change-status.button') }}
              </b-button>
              <b-button @click="modals.createOrder.visibleModal = true" class="w-xl mx-2 px-4"
                        variant="outline-warning">{{
                  $t('core-orders.add-order.button')
                }}
              </b-button>

              <div class="d-flex flex-sm-column mx-2">
                <label class="font-size-12">
                  {{
                    $t('core-orders.add-manually-order.button', {'status': $t(this.selectors.manuallyAddOrder ? 'message.yes' : 'message.no')})
                  }}
                </label>
                <switches v-model="selectors.manuallyAddOrder" class="my-0" color="danger"/>
              </div>
            </div>
          </div>
        </div>

        <div class="py-1">
          <div class="row mt-2">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  {{ $t('table.show') }}&nbsp;
                  <b-form-select v-model="table.perPage" size="sm"
                                 :options="table.pageOptions"></b-form-select>&nbsp;{{ $t('table.entries') }}
                </label>
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                <label class="d-inline-flex align-items-center">
                  {{ $t('table.search') }}
                  <b-form-input v-model="table.filter" class="form-control form-control-sm ml-2" type="search"
                                @keyup.enter="setToFirstPageAndRefresh"
                                @input="setToFirstPageAndRefresh"></b-form-input>
                </label>
              </div>
            </div>
          </div>

          <div class="row my-2">
            <div class="col-sm-12 col-md-6">
              <div>
                <b-dropdown dropleft variant="info" class="w-xl">
                  <template slot="button-content">{{ $t('core-orders.legend.button') }}</template>
                  <b-dropdown-item-button>Status po lewej stronie:</b-dropdown-item-button>
                  <b-dropdown-item-button><span class="badge color-text-white status-background-11">Osiągnieto stan krytyczny</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button>Status po prawej stronie:</b-dropdown-item-button>
                  <b-dropdown-item-button><span class="badge color-text-white status-background-1">Niezamówiony (nieopłacony) | stan krytyczny: po 2 godz.</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button><span class="badge color-text-white status-background-2">Niezamówiony (opłacony) | stan krytyczny: po 30 min.</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button><span
                      class="badge color-text-white status-background-3">Zamówiony u dostawcy | stan krytyczny: po 2 godz.</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button><span class="badge color-text-white status-background-4">Zarezerwowany u dostawcy | stan krytyczny: po 2 godz.</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button><span
                      class="badge color-text-white status-background-5">Opłacony u dostawcy | stan krytyczny: po 2 godz.</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button><span
                      class="badge color-text-white status-background-6">Realizacja z magazynu | stan krytyczny: po 1 godz.</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button><span class="badge color-text-white status-background-7">Przygotowany do wysyłki - hurtownia | stan krytyczny: po 12 godz.</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button><span class="badge color-text-white status-background-8">Wysłany z hurtowni do nas | stan krytyczny: po 24 godz.</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button><span class="badge color-text-white status-background-9">Zwrot | stan krytyczny: po 24 godz.</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button><span class="badge color-text-white status-background-9">Zwrot przyjęty na magazyn | stan krytyczny: po 24 godz.</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button><span class="badge color-text-white status-background-10">Reklamacja | stan krytyczny: po 24 godz.</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button><span class="badge color-text-white status-background-12">Wysłany do klienta z hurtowni | stan krytyczny: po 24 godz.</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button><span class="badge color-text-white status-background-13">Wysłany do klienta z magazynu | stan krytyczny: po 24 godz.</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button><span class="badge color-text-white status-background-14">Poczekalnia | stan krytyczny: po 24 godz.</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button><span
                      class="badge color-text-white status-background-15">Gotowy do odbioru | stan krytyczny: po 24 godz.</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button><span class="badge color-text-white status-background-15">Umieszczona w paczkomacie lub punkcie odbioru | stan krytyczny: po 24 godz.</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button><span class="badge color-text-white status-background-12">Odebrana przez kuriera | stan krytyczny: po 24 godz.</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button><span class="badge color-text-white status-background-16">Zwrot odesłany do klienta | stan krytyczny: po 24 godz.</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button><span class="badge color-text-white status-background-17">Zrealizowany</span></b-dropdown-item-button>
                  <b-dropdown-item-button><span class="badge color-text-white status-background-18">Anulowany</span></b-dropdown-item-button>
                </b-dropdown>
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div class="float-right">
                <div class="d-inline-flex justify-content-end">
                  <div class="dataTables_paginate paging_simple_numbers">
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination v-model="table.currentPage" :total-rows="table.rows"
                                    :per-page="table.perPage"></b-pagination>
                    </ul>
                  </div>
                  <div class="align-items-center">
                    <label class="d-inline-flex align-items-center mx-2">
                      <b-form-input v-model="table.inputPage" class="form-control form-control-sm ml-2 form-xs"/>
                    </label>
                    <b-button variant="primary" @click="paginationHelper.setCurrentPage(table, table.inputPage)">Idź do
                      strony
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="table-responsive">
            <b-table
                ref="table"
                :items="loadShopOrders"
                :fields="getFields()"
                responsive="sm"
                :per-page="table.perPage"
                :current-page="table.currentPage"
                :sort-by.sync="table.sortBy"
                :sort-desc.sync="table.sortDesc"
                :filter-included-fields="table.filterOn"
                :busy.sync="table.isBusy"
                @filtered="elements => paginationHelper.onFiltered(table, elements)"
                :empty-text="$t('message.no-elements')"
                :empty-filtered-text="$t('message.no-records')" :show-empty="true"><!--:tbody-tr-class="rowOrderItem"-->
              <div slot="table-busy" class="text-center">
                <h5>{{ $t('message.loading') }}</h5>
                <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
              </div>

              <template v-slot:cell(id)="{ item }">
                <div :class="getProductLeftLabelColor(item)" class="status-data">
                  <span :id="item.id">
                    {{ getIdText(item) }}
                    <span v-if="deliveryAddress(item.id)" :class="`fi fi-${deliveryAddress(item.id).country.toLowerCase()}`" style="font-size: 24px;" />
                  </span>
                </div>
              </template>

              <template v-slot:cell(totalProductsCostTaxExcluded)="{ item }">
                <div :class="getProductLeftLabelColor(item)" class="status-data">
                  <span>{{ item.totalProductsCostTaxExcluded.toFixed(2) + " PLN" }} </span>
                </div>
              </template>

              <template v-slot:cell(pricesSum)="{ item }">
                <div :class="getProductLeftLabelColor(item)" class="status-data">
                  <span>{{ item.pricesSum.toFixed(2) + " PLN" }} </span>
                </div>
              </template>

              <template v-slot:cell(lastModification)="{ item }">
                <div :class="getProductLeftLabelColor(item)" class="status-data">
                  <template v-if="item.lastModifierEmail">
                    <span>{{ item.lastModifierFirstName }} {{ item.lastModifierLastName }}</span>
                    <br>
                    <span>({{ item.lastModifierEmail }})</span>
                  </template>
                  <template v-else>
                    <span>Brak</span>
                  </template>
                </div>
              </template>

              <template v-slot:cell(action)="{ item }">
                <div :class="getProductRightLabelColor(item)" class="status-data">
                  <div class="actions">
                    <div class="button-items" style="padding-top: 5px;">
                      <a :href="getOrderDetailsUrl(item)" :id="`order-details-${item.id}`"
                         class="btn btn-sm btn-primary border-dark">Szczegóły</a>

                      <template v-if="!item.deleted">
                        <template v-if="item.archived">
                          <b-button @click="archiveOrUnArchiveOrder(item, false)" variant="success"
                                    class="btn-sm border-dark">Przywróć
                          </b-button>
                          <!--                        <a @click="archiveOrUnArchiveOrder(item, false)" :id="`unarchive-order-${item.id}`" class="clickable-element mr-4 text-success"><i-->
                          <!--                            class="mdi mdi-backup-restore font-size-22"></i></a>-->
                          <!--                        <b-tooltip :target="`unarchive-order-${item.id}`" placement="right">Przywróć na kartę zamówień</b-tooltip>-->
                        </template>
                        <template v-else>
                          <b-button @click="archiveOrUnArchiveOrder(item, true)" variant="warning"
                                    class="btn-sm border-dark">Archiwizuj
                          </b-button>
                          <!--                        <a @click="archiveOrUnArchiveOrder(item, true)" :id="`archive-order-${item.id}`" class="clickable-element mr-4 text-warning"><i-->
                          <!--                            class="mdi mdi-archive font-size-22"></i></a>-->
                          <!--                        <b-tooltip :target="`archive-order-${item.id}`" placement="right">Archiwizuj zamówienie</b-tooltip>-->
                        </template>
                      </template>

                      <template v-if="item.archived">
                        <template v-if="item.deleted">
                          <b-button @click="deleteOrder(item, false)" variant="success" class="btn-sm border-dark">
                            Przywróć do zarchiwizowanych
                          </b-button>
                          <!--                        <a @click="deleteOrder(item, false)" :id="`undelete-order-${item.id}`" class="clickable-element mr-4 text-success"><i-->
                          <!--                            class="mdi mdi-backup-restore font-size-22"></i></a>-->
                          <!--                        <b-tooltip :target="`undelete-order-${item.id}`" placement="right">Przywróć na kartę zamówień zarchiwizowanych</b-tooltip>-->
                        </template>
                        <template v-else>
                          <b-button @click="deleteOrder(item, true)" variant="danger" class="btn-sm border-dark">Usuń
                            zamówienie
                          </b-button>
                          <!--                        <a @click="deleteOrder(item, true)" :id="`delete-order-${item.id}`" class="clickable-element mr-4 text-danger"><i-->
                          <!--                            class="mdi mdi-delete font-size-22"></i></a>-->
                          <!--                        <b-tooltip :target="`delete-order-${item.id}`" placement="right">Usuń zamówienie</b-tooltip>-->
                        </template>
                      </template>

                      <order-address-button :deliveryAddresses="deliveryAddresses" :shopOrderId="item.id"/>
                    </div>
                  </div>
                </div>
              </template>

              <template v-slot:cell(paymentStatus)="{ item }">
                <div :class="getProductRightLabelColor(item)" class="status-data">
                                  <span class="badge" :class="paymentStatusColor(item.sbzPaymentStatus)">{{
                                      paymentStatusText(item.sbzPaymentStatus)
                                    }}</span>
                </div>
              </template>

              <template v-slot:row-details="orderTable">
                <b-table :bordered=true :items="getOrderProducts(orderTable.item)"
                         :fields="getProductsFields()"
                         :tbody-tr-class="highlightRow"
                         class="text-center hovered-order"
                         :empty-text="$t('message.no-elements')"
                         :empty-filtered-text="$t('message.no-records')" :show-empty="true">

                  <template v-slot:cell(status)="{ item }">
                    <span :class="orderHelper.getProductStatusColor(item.shopOrderProductStatus)">{{
                        $t('core-orders.product.status.' + item.shopOrderProductStatus)
                      }}</span>
                  </template>

                  <template v-slot:cell(name)="{ item }">
                    <div class="custom-control custom-checkbox">
                      <input :value="item.id" v-model="modals.changeProductStatus.productIds" type="checkbox"
                             class="custom-control-input" :id="`check-${item.id}`"/>
                      <label class="custom-control-label" :for="`check-${item.id}`">&nbsp;</label>

                      <a class="ecat-text-break" :href="`/dashboard/admin/product/show/${item.productId}`" target="_blank">{{ item.name }}</a>
                    </div>
                  </template>

                  <template v-slot:cell(warehouse)="{ item }">
                    <a :href="`/dashboard/admin/warehouse/show/${item.warehouseId}`"
                       target="_blank">{{ item.warehouseName }}</a>
                  </template>
                </b-table>
              </template>
            </b-table>
          </div>

          <div class="row">
            <div class="col">
              <div class="float-left">
                <p>{{
                    $t('table.entries-footer', {
                      'amount': paginationHelper.getElementsAmount(table),
                      'elements': paginationHelper.getElementsCount(table),
                      'all': table.totalRows
                    })
                  }}</p>
              </div>

              <div class="dataTables_paginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination v-model="table.currentPage" :total-rows="table.rows"
                                :per-page="table.perPage"></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
        id="modal-1"
        v-model="modals.changeProductStatus.visibleModal"
        hide-footer
        title="Zmiana statusów produktów"
        title-class="font-18"
        @esc="disableModalChangeProductStatus"
        @hide="disableModalChangeProductStatus">

      <div class="form-group">
        <label>Ilość zaznaczonych produktów: {{ modals.changeProductStatus.productIds.length }}</label>
      </div>

      <div class="form-group">
        <label>Status</label>
        <vue-multiselect v-model="modals.changeProductStatus.status"
                         :options="shopOrderProductStatuses ? shopOrderProductStatuses : []"
                         :custom-label="value => $t('core-orders.product.status.' + value)"
                         placeholder="Wybierz status produktu"
                         :class="{ 'is-invalid': $v.modals.changeProductStatus.status.$error }"
                         :show-labels="false">
        </vue-multiselect>
        <div v-if="!$v.modals.changeProductStatus.status.required" class="invalid-feedback">
          {{ $t('message.required') }}
        </div>
      </div>

      <div class="text-center">
        <b-button variant="success" @click="changeProductStatus">
          Zapisz
        </b-button>
        <b-button class="ml-1" variant="danger" @click="disableModalChangeProductStatus">
          {{ $t('message.cancel') }}
        </b-button>
      </div>
    </b-modal>

    <b-modal
        id="modal-2"
        v-model="modals.createOrder.visibleModal"
        hide-footer
        :title="selectors.manuallyAddOrder ? 'Tworzenie zamówienia stacjonarnego' : 'Tworzenie zamówienia'"
        title-class="font-18"
        @esc="hideCreateOrderModal"
        @hide="hideCreateOrderModal">

      <template v-if="!selectors.manuallyAddOrder">
        <div class="form-group">
          <ecat-multiselect @change="value => modals.createOrder.order.shopId = value" label="Wybierz Sklep"
                            load-url="/shop/list/pagination" query-url="/shop/by-name" save-id="id" view-id="name"
                            param="name"></ecat-multiselect>
        </div>

        <div class="form-group">
          <label>Imię</label>
          <input id="firstName" v-model="modals.createOrder.order.firstName" class="form-control" type="text"
                 :class="{ 'is-invalid': $v.modals.createOrder.order.firstName.$error }"/>
          <div v-if="!$v.modals.createOrder.order.firstName.required" class="invalid-feedback">
            {{ $t('message.required') }}
          </div>
        </div>

        <div class="form-group">
          <label>Nazwisko</label>
          <input id="lastName" v-model="modals.createOrder.order.lastName" class="form-control" type="text"
                 :class="{ 'is-invalid': $v.modals.createOrder.order.lastName.$error }"/>
          <div v-if="!$v.modals.createOrder.order.lastName.required" class="invalid-feedback">
            {{ $t('message.required') }}
          </div>
        </div>

        <div class="form-group">
          <label>Email</label>
          <input id="email" v-model="modals.createOrder.order.email" class="form-control" type="text"
                 :class="{ 'is-invalid': $v.modals.createOrder.order.email.$error }"/>
          <div v-if="!$v.modals.createOrder.order.email.required" class="invalid-feedback">
            {{ $t('message.required') }}
          </div>
        </div>

        <div class="form-group">
          <ecat-multiselect @change="value => modals.createOrder.order.carrierId = value" label="Metoda wysyłki"
                            load-url="/carrier/list/pagination" query-url="/carrier/by-name" save-id="id" view-id="name"
                            param="name"></ecat-multiselect>
        </div>

        <template v-if="modals.createOrder.order.carrierId">
          <div class="form-group" :set="selectedCarrier = getCarrier(modals.createOrder.order.carrierId)">
            <template v-if="selectedCarrier && selectedCarrier.name.toLowerCase().includes('paczkomat')">
              <label>Kod paczkomatu</label>
              <input v-model="modals.createOrder.order.parcelLockerCode" class="form-control" type="text"/>
            </template>
          </div>
        </template>

        <div class="form-group">
          <label>Ulica</label>
          <input id="street" v-model="modals.createOrder.order.street" class="form-control" type="text"
                 :class="{ 'is-invalid': $v.modals.createOrder.order.street.$error }"/>
          <div v-if="!$v.modals.createOrder.order.street.required" class="invalid-feedback">
            {{ $t('message.required') }}
          </div>
        </div>

        <div class="form-group">
          <label>Miasto</label>
          <input id="city" v-model="modals.createOrder.order.city" class="form-control" type="text"
                 :class="{ 'is-invalid': $v.modals.createOrder.order.city.$error }"/>
          <div v-if="!$v.modals.createOrder.order.city.required" class="invalid-feedback">{{ $t('message.required') }}
          </div>
        </div>

        <div class="form-group">
          <label>Kod pocztowy</label>
          <input id="city" v-model="modals.createOrder.order.postCode" class="form-control" type="text"
                 :class="{ 'is-invalid': $v.modals.createOrder.order.postCode.$error }"/>
          <div v-if="!$v.modals.createOrder.order.postCode.required" class="invalid-feedback">
            {{ $t('message.required') }}
          </div>
        </div>

        <div class="form-group">
          <label>Kraj</label>
          <select id="phone-country" v-model="modals.createOrder.order.country"
                  :class="{ 'is-invalid': $v.modals.createOrder.order.country.$error }"
                  class="custom-select">
            <option v-for="(item, index) in getAllCountries()" :key="index" :value="`${item.iso2}`">
              {{ item.name }} ({{ item.iso2 }})
            </option>
          </select>
          <div v-if="!$v.modals.createOrder.order.country.required" class="invalid-feedback">
            {{ $t('message.required') }}
          </div>
        </div>

      </template>
      <template v-else>
        <p>Po kliknięciu w "potwierdź" zostanie wygenerowane nowe zamówienie stacjonarne.</p>
      </template>

      <div class="text-center">
        <b-button variant="success" @click="createOrder">
          {{ selectors.manuallyAddOrder ? 'Potwierdź' : 'Zapisz' }}
        </b-button>
        <b-button class="ml-1" variant="danger" @click="hideCreateOrderModal">
          {{ $t('message.cancel') }}
        </b-button>
      </div>
    </b-modal>
  </Layout>
</template>

<style>
.highlighted-order {
  background-color: var(--order-selected-bg);
}

.default-order {
  background-color: var(--order-normal-bg);
}

.hovered-order tbody tr:hover {
  background-color: var(--order-hover-bg) !important;
}
</style>